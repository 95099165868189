import React from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import "./App.css";
import Home from "./components/pages/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Resume from "./components/pages/Resume";
import Skills from "./components/pages/Skills";
import ContactMe from "./components/pages/ContactMe";
import resume from "../src/files/Bryant Bardales CS Resume.pdf";

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/resume" element={<Resume pdf={resume} />} />   {/* pdf={resume} references pdf file in files */}
          <Route path="/skills" element={<Skills />} />
          <Route path="/contact-me" element={<ContactMe />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
