import React from "react";
import "../css/Cards.css";
import YouTube from "react-youtube";

function Cards() {
  const onReady = (e) => {
    console.log(e.target);
  };

  return (
    <div className="cards">
      <p className="text-description-left">
        {" "}
        {/*Breaking Ground Job*/}
        As assistant building director for Breaking Ground, I am currently
        responsible for ensuring effective property management and day-to-day
        operations at my assigned site. My day to day operations include but are
        not limited to rent collection and administration; lease renewals;
        subsidy management; tenant services; and general office management. I
        work closely with the Property Management team, Leasing and Compliance
        team, and our Finance team on special projects and liaise with different
        positions for the daily operations of the building. I supervise Rent
        Administrators and Office Managers and work closely with tenants,
        visitors, social service partners, auditors, regulatory agencies, and
        outside counsel to ensure all building operations run smoothly and all
        residents needs are met. I also oversee all landlord-tenant legal
        affairs at my building in keeping with applicable laws and regulations
      </p>

      <div className="right-card">
        <h1>
          <u>Breaking Ground</u>
        </h1>
        <img
          src="/images/BGLogo.png"
          alt="Breaking Ground Logo"
          className="bglogo"
        />
      </div>

      <div className="left-card">
        <h1>
          <u>The Michaels Organization </u>
        </h1>
        <img src="/images/tmo-logo.png" alt="TMO Logo" className="tmo-logo" />
      </div>

      <p className="text-description-right">
        {" "}
        {/*The Michael's Org Job*/}
        As site assistant office manager for The Michael's Organization, my job
        was to ensure all of my site building operations ran smoothly on a day
        to day basis. I was responsible for directing file preparation under my
        manager's supervision for the Management and Occupancy Review inspection
        and worked as a team with my manager and assistant manager to obtain an
        above-average score. I managed bi-weekly meetings with my maintenance
        team to establish a team-building environment, verify agreement on work
        tasks, and maintain an open dialogue with all employees on concerns and
        questions. I assisted my manager by analyzing ERAP and resident
        delinquency data to create data visualization reports using Microsoft
        Suite and Google Suite. Using my background in technology, I also
        provided IT support by fixing various printer/computer issues and
        maintaining local office network systems running.
      </p>

      <p className="text-description-left">
        As intern front-end developer for CKM Analytix, I was hired in the
        summer of 2022 and was assigned to work on three projects during the
        duration of my internship. First, I was assigned to work on a personal
        blog project that was Python and Django-based as a Minimum Variable
        Project that I then presented to my manager and the company CTO as a way
        to prepare to work on the internal HR app. After a successful
        presentation of my personal project, I was then assigned to work on the
        internal HR hiring portal, but unfortunately the project build ran into
        a few hiccups as the build was old and hadn't been worked on in a while.
        I was then reassigned and quickly shifted roles to be assigned on the
        company's main product called "T-K." I quickly adapted to the Agile
        methodology framework and used JavaScript, Vue.js, and libraries such as
        Plotly.js to improve the user experience for the client on the front-end
        and Kotlin to improve the backend.
      </p>

      <div className="right-card">
        <h1>
          <u>CKM Analytix</u>
        </h1>
        <img
          src="/images/CKMLogo.png"
          alt="CKM Analytix Logo"
          className="ckmlogo"
        />
      </div>

      <div className="left-card">
        <h1>
          <u>NYC's Tech Talent Pipeline Program</u>
        </h1>
        <img src="/images/NYCTTPLogo.png" alt="TTP Logo" className="ttp-logo" />
      </div>

      <p className="text-description-right">
        {" "}
        {/*Tech Talent Pipeline */}
        Selected out of hundreds of applicants through various personal
        interviews and rigorous technical interviews to participate as a
        resident in the Cohort 3 of the Tech Talent Pipeline Program (TTP) in
        Brooklyn College that provided hands-on training in full-stack web
        development. During my residency at the TTP Program, I completed 11
        group coding projects during a one-month long web-intensive training
        program using frameworks, database systems, and environments such as
        Node.js, Express, React.js, and PostgreSQL. At the end of the program, I
        worked in a group to create a final capstone project called "We Found."
        The project is an e-commerce site with basic CRUD and account creation
        functionality that allowed them to login, log out and save preferences
        each time they shopped. The users are also able to view shopping item
        prices, descriptions using data pulled from Kroger's supermarket API to
        also add, and remove items from their cart at will.
        <div className="player">
          <YouTube videoId={"-bmEfTJ1Gic"} onReady={onReady} />
        </div>
      </p>

      <p className="text-description-left">
        {" "}
        {/*Google's M&D Program */}
        Chosen out of hundreds of applicants to partake in a unique, virtual
        Google Mentorship and Development Program offered through LaGuardia
        Community College. In this program, I was personally mentored by a
        Google Engineer who provided me with insight and assistance to improve my
        resume, my coding skills, and my studies as a Computer Science student.
        During a 2-week part of the program, I was given an intensive
        introductory project-based Python processing curriculum that was taught
        by Google engineers and completed 10 individual coding projects by using
        concepts such as variables, conditionals, loops and functions.
      </p>

      <div className="right-card">
        <h1>
          <u>Google's Mentorship and Development Program</u>
        </h1>
        <img
          src="/images/GoogleLogo.png"
          alt="Google Logo"
          className="google-logo"
        />
      </div>

      <div className="left-card">
        <h1>
          <u>LaGuardia Community College</u>
        </h1>
        <img
          src="/images/LAGCCLogo.svg"
          alt="LAGCC Logo"
          className="lagcc-logo"
        />
      </div>

      <p className="text-description-right">
        {" "}
        {/*LAGCC*/}
        After dropping out of college during my Freshman year in 2018 and
        working various small jobs thereafter, I made the decision to return to
        school and chose LaGuardia Community College (LAGCC). LAGCC helped me on
        my journey to achieve what I thought was difficult; to obtain a degree
        in Computer Science. The decision to join LAGCC was based on the
        experience my wife had while she was attending college and it became an
        un-regrettable decision for me. I was shown nothing but support from
        staff and professors especially during very the very difficult and
        confusing times of COVID. During my time at LAGCC, I was invited to and
        joined Phi Theta Kappa Honor Society for my outstanding grades, the Tech
        Scholars program, and Google's Computer Science Summer Institute where I
        was mentored by a Google Software Engineer. I ultimately graduated Cum
        Laude with a Associate of Science in Computer Science in June of 2021
        with a 3.8 GPA.
        <div className="player">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/cMhOVPp8oqk?si=CoavQaPezcFxQj7R&amp;clip=UgkxRtYBkaL-3gMoZLwMs-676nYKQUcD414_&amp;clipt=EKSDqgEYrKqqAQ"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
      </p>

      <p className="text-description-right">
        {" "}
        {/*Brooklyn College*/}After graduating from LAGCC, I wanted to finish my
        undergrad journey by obtaining a bachelor's degree. I decided that I
        would apply to Brooklyn College to complete my bachelor's degree in
        Computer Science and was ultimately accepted. Thankfully, during my
        tenure at Brooklyn College, civilization as a whole started to recover
        from the COVID pandemic and I was allowed to attend the beautiful campus
        and meet peers and professors in person. During my time at Brooklyn
        College, I applied and was selected to become a recipient of the Guttman
        Transfer Scholarship. I also became a part of the Computer Science Club,
        and was selected to be a part of the Tech Talent Pipeline Program. I
        graduated Cum Laude from with an Bachelor of Science in Computer Science
        in June of 2023 with a 3.5 GPA.
        <div className="player">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/NsK45aJOT-U?si=mR20xGYs3VoZlqU5&amp;clip=UgkxdKtrjSHJVNb6kphvDq4260DJSygLEgvi&amp;clipt=EMHOlQYYyfWVBg"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </p>

      <div className="left-card">
        <h1>
          <u>Brooklyn College</u>
        </h1>
        <img
          src="/images/BrooklynCollegeLogo.png"
          alt="Brooklyn College Logo"
          className="bc-logo"
        />
      </div>
    </div>
  );
}

export default Cards;
