import React from "react";
import "../../css/Resume.css";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function Resume(props) {

  function onLoadError(error) {
    console.log(error);
  }

  function onSourceError(error) {
    console.log(error);
  }
  const { pdf } = props;

  return (
    <>
      {/* <h1 className="resume">RESUME</h1> */}
      {/* Function to display the PDF Resume on the page */}
      
      <div className="all-page-container">
        <Document
          file={pdf}
          onSourceError={onSourceError}
          onLoadError={onLoadError}
        >
          <Page
            renderTextLayer={false}
            renderAnnotationLayer={false}
            scale={1.5}
            pageNumber={1}
          />
        </Document>
      </div>
    </>
  );
}
