import React from "react";
import "../App.css";
import "../css/HeroSection.css";
import Typewriter from "typewriter-effect";

function HeroSection() {
  // Welcome and name sign that transitions words using Typewriter Effect for React.
  return (
    <div className="hero-container">
      <video src="/videos/stock-type-vid.mov" autoPlay loop muted />

      <img
        className="profile-picture"
        src={"/images/ProfilePicture.JPG"}
        alt="Bryant Profile"
      />

      <h1 className="main-title">
        <Typewriter
          onInit={(typewriter) => {
            typewriter
              .typeString("Hi, I'm Bryant Bardales")
              .callFunction(function (state) {
                //Function stops the blinking cursor
                state.elements.cursor.style.display = "none";
              })
              .start();
          }}
        />
      </h1>

      <p>
        <Typewriter
          onInit={(typewriter) => {
            typewriter
              .pauseFor(3500)
              .typeString(
                'I am a <span style="color: #CF9FFF;">college graduate</span>.' //light violet
              )
              .pauseFor(2000)
              .deleteChars(19);
            typewriter
              .typeString('<span style="color: #33FBFF;">determined</span>.') //light blue
              .pauseFor(1000)
              .deleteChars(11);
            typewriter
              .typeString('<span style="color: #FFD233;">motivated</span>.') //yellow
              .pauseFor(1000)
              .deleteChars(10);
            typewriter
              .typeString(
                '<span style="color: #0BD800;">la</span><span style="color: #FFFFFF;">ti</span><span style="color: #FF3333;">no</span>.'
              ) //green,white,red
              .pauseFor(1000)
              .deleteChars(8);
            typewriter
              .typeString(
                'a<span style="color: #73C6B6;"> certified LIHTC specialist</span>.'
              ) //orange
              .pauseFor(1000)
              .deleteChars(29);
            typewriter
              .typeString(
                'an<span style="color: #73C6B6;"> assistant building director</span>.'
              ) //orange
              .pauseFor(1000)
              .deleteChars(30);
            typewriter
              .typeString(
                '<span style="color: #F77C3F;"> software engineer</span>.'
              ) //orange
              .start();
          }}
        />
      </p>
    </div>
  );
}

export default HeroSection;
