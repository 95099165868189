import React from 'react';
import '../../App.css';

export default function Skills() {
  return (
    <>
      <h1 className='skills'>Under Construction</h1>
    </>
  );
}
